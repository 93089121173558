<template>
	<div class="exception-body flex align-items-center justify-content-center">
		<div class="exception-panel p-5 flex flex-column justify-content-center">
			<h4 class="font-bold">NOT FOUND</h4>
			<span class="mb-4 line-height-3">Looks like you're a little lost, as there's nothing here.</span>

			

			<Button type="button" label="Return to Dashboard" @click="goDashboard"></Button>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			goDashboard(){
				this.$router.push({ path: '/' });
			}
		}
	}
</script>

<style scoped>

</style>
